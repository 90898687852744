exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-4-js": () => import("./../../../src/pages/services-4.js" /* webpackChunkName: "component---src-pages-services-4-js" */),
  "component---src-pages-services-5-js": () => import("./../../../src/pages/services-5.js" /* webpackChunkName: "component---src-pages-services-5-js" */)
}

